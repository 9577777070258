// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// `.env.ts` is generated by the `npm run env` command
import { Config } from 'timeghost-api';
import git from '../../git.json';
import { env } from './.env';
import baseEnvironment from './baseEnvironment';
export const environment = {
  ...baseEnvironment,
  isdev: false,
  chargebeeSite: 'timeghost',
  version: env.npm_package_version + '#' + git.hash,
  hash: git.hash,
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'de-DE', 'es-ES', 'pt-BR', 'fr-FR', 'nl-NL', 'da-DA'],
  production: true,
  enableSentry: true,
  clientUrl: 'https://web.timeghost.io',
  baseUrl: 'https://web.timeghost.io',
  serverUrl: 'https://timeghost-api.azurewebsites.net/api',
  azureFunctionsKey: 'FpKJbpy5NuSYnY1v9Ar4cKwi2lTPFtoUanXtnkoJZY8yQhpefgHfCQ==',
  adalConfig: {
    clientId: 'f6f894ce-5b44-4c9b-aff4-253d2fbe8a99',
    endpoints: {
      graphApiUri: 'https://graph.microsoft.com',
    },
    apiAccessUrl: 'api://timetracking.timeghost.io/f6f894ce-5b44-4c9b-aff4-253d2fbe8a99/user_impersonation',
  },
  paddle: {
    vendorId: 123796,
    product: {
      monthly: 638050,
      yearly: 638061,
    },
  },
  microsoft: {
    fulfillment:
      'https://appsource.microsoft.com/de-de/product/web-apps/schgesellschaftfrprozessmanagementmbh1592311158227.timeghost_fulfillment?tab=Overview',
  },
  features: {
    extraLanguages: <string[]>[],
  },
  services: {
    webex: {
      clientId: 'C45d4396c5d000c62f998cd61a3db049a370bef5d0a547d5a6d1f5007c036879e',
      redirectUri: 'https://timeghost-api.azurewebsites.net/api/Webex_Communications_Api',
      scopes: ['meeting:schedules_read', 'meeting:participants_read', 'meeting:preferences_read'],
    },
  } as Config['services'],
  // @ts-ignore
  releaseName: null,
};
