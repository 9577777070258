import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSettingsQuery } from 'timeghost-api';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
@Injectable({
  providedIn: 'root',
})
export class AppSubscriptionGuard {
  constructor(private userSettingsQuery: UserSettingsQuery, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userSettings = this.userSettingsQuery.getValue();
    return new Promise((resolve) => {
      const ws = userSettings.workspace;
      if (!ws) resolve(false);
      const status = parseSubscriptionAsStatus(userSettings.workspace, userSettings);
      if (!status) {
        window.dataLayer.push({
          purchase_c2a: 'auto redirect to plans',
        });

        return resolve(this.router.createUrlTree(['account/profile']));
      }
      if (status.isTrial && (status.isExpired || status.isAborted)) {
        if (!status.isOwner) return resolve(this.router.createUrlTree(['account/profile']));
        window.dataLayer.push({
          purchase_c2a: 'auto redirect to plans',
        });
        if (status.isChargebee) return resolve(this.router.createUrlTree(['settings', 'workspace']));
        return resolve(this.router.createUrlTree(['settings', 'workspace', 'plans']));
      }
      if (status.isPaused) {
        if (!status.isOwner) return resolve(this.router.createUrlTree(['account/profile']));
        if (status.isChargebee) return resolve(this.router.createUrlTree(['settings', 'workspace']));
        return resolve(this.router.createUrlTree(['settings', 'workspace', 'subscriptions', 'edit']));
      }
      if (status.isExpired) {
        if (!status.isOwner) return resolve(this.router.createUrlTree(['account/profile']));
        if (status.isChargebee) return resolve(this.router.createUrlTree(['settings', 'workspace']));
        return resolve(this.router.createUrlTree(['settings', 'workspace', 'plans']));
      }
      if (!status.isCurrentUserInSlotRange)
        return resolve(this.router.createUrlTree(['settings', 'workspace', 'team']));
      if (status.isUserSlotsExhausted && !status.isCurrentUserInSlotRange) {
        if (!status.isOwner) return resolve(this.router.createUrlTree(['account/profile']));
        window.dataLayer.push({
          purchase_c2a: 'auto redirect to plans',
        });
        if (status.isChargebee && (status.isExpired || status.isAborted))
          return resolve(this.router.createUrlTree(['settings', 'workspace']));
        if (status.isTrial) return resolve(this.router.createUrlTree(['settings', 'workspace', 'plans']));
        else if (!status.isCurrentUserInSlotRange)
          return resolve(this.router.createUrlTree(['settings', 'workspace', 'subscriptions', 'edit']));
      }
      return resolve(true);
    });
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
