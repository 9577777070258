import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { pushError } from '@app/_helpers/globalErrorHandler';
import { isBreakRuleError, isWorkingHoursError } from '@app/_helpers/is-error-object';
import { hasPermissionByKey } from '@app/_helpers/permission';
import { toPromise } from '@app/_helpers/promise';
import {
  asyncWrapTimeout,
  createRxValue,
  fromRxValue,
  getActiveSchedule,
  parseScheduleStats,
} from '@app/_helpers/utils';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  endOfDay,
  endOfWeek,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  roundToNearestMinutes,
  startOfToday,
  startOfWeek,
} from 'date-fns/esm';
import produce from 'immer';
import { capitalize } from 'lodash-es';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { combineLatest, combineLatestWith, interval, map, startWith, switchMap } from 'rxjs';
import { firstBy } from 'thenby';
import {
  ApplicationSettingsQuery,
  ComegoQuery,
  ComegoService,
  ComegoTime,
  ComegoTimeType,
  Logger,
  UserSettingsQuery,
} from 'timeghost-api';
import { ComeAndGoCreateDialogComponent } from '../come-and-go-create-dialog/come-and-go-create-dialog.component';
import {
  ComeAndGoUpdateDialogComponent,
  ComeGoUpdateDialogData,
} from '../come-and-go-update-dialog/come-and-go-update-dialog.component';
const STORE_KEY = 'cag';
const log = new Logger('ComeAndGoBoxComponent');
const typeNameMap = {
  pause: 'comego.state.pause',
  play: 'comego.check_in',
};
@UntilDestroy()
@Component({
  selector: 'tg-come-and-go-box',
  templateUrl: './come-and-go-box.component.html',
  styleUrls: ['./come-and-go-box.component.scss'],
})
export class ComeAndGoBoxComponent implements OnInit {
  readonly isLoading = createRxValue(false);
  get loading() {
    return this.isLoading.value;
  }
  set loading(value: boolean) {
    this.isLoading.update(value);
  }
  constructor(
    private appSettings: ApplicationSettingsQuery,
    private comego: ComegoService,
    private comegoQuery: ComegoQuery,
    private recordService: RecordToolbarService,
    private userSettingsQuery: UserSettingsQuery,
    private dialog: MatDialog
  ) {}
  readonly now = new Date();
  readonly now$ = interval(900).pipe(
    startWith(0),
    untilDestroyed(this),
    map(() => new Date())
  );
  readonly workspace$canCreate = this.userSettingsQuery
    .select()
    .pipe(map((x) => hasPermissionByKey(x, 'groupsCanComegoCreateTimes' as any)));
  readonly activeTime$ = fromRxValue(
    this.comegoQuery.selectAllFromCurrentUser({ filterBy: (x: any) => !x.end && !x.deleted }).pipe(
      combineLatestWith(this.userSettingsQuery.select()),
      map(([x, user]) => x?.find((t) => t.user.id === user.id) || null),
      debounceTimeAfterFirst(200)
    ),
    null,
    null
  );
  readonly activeTime$times = combineLatest([
    this.activeTime$.asObservable(),
    this.comegoQuery.selectAll(),
    this.userSettingsQuery.select(),
  ]).pipe(
    map(([active, times, user]) => {
      if (!active) return null;
      const start = new Date(active.start);
      return times
        .filter((x) => x.user.id === user.id && isSameDay(start, new Date(x.start)))
        .map(({ start, end, ...x }) => ({ start: new Date(start), end: (end && new Date(end)) || null, ...x }));
    })
  );
  readonly activeTime$stats = combineLatest([this.activeTime$.asObservable(), this.now$, this.activeTime$times]).pipe(
    map(([time, now, times]) => {
      if (!time) return null;
      const start = new Date(time.start);
      const gapRanges = times || [];
      const last = gapRanges[gapRanges.length - 1];
      const duration = (now.getTime() - start.getTime()) / 1000;
      return {
        start,
        last,
        paused: time?.type === 'pause',
        gaps: gapRanges,
        duration,
        now: now.getTime(),
      };
    })
  );
  readonly scheduleType = createRxValue(false); // weekly = true, daily = false
  readonly isActive$ = this.activeTime$.asObservable().pipe(map((x) => !!x));
  readonly activeTime$tooltip = combineLatest([
    this.activeTime$.asObservable(),
    this.activeTime$stats,
    this.userSettingsQuery.select(),
    this.activeTime$times,
    this.scheduleType.asObservable(),
  ]).pipe(
    switchMap(async ([active, stats, user, activeTimes, isWeekly]) => {
      if (!active) return null;
      const gaps =
        activeTimes
          ?.map(({ type, ...x }) => {
            if (x.id === active.id) return null;
            return {
              time: new Date(x.start),
              type,
              isPause: type === 'pause',
              typeName: 'comego.types.' + type,
            };
          })
          .filter(Boolean) || [];

      const now = startOfToday();
      const schedule = stats && getActiveSchedule(user, stats.start);
      const currentDay = now.getUTCDay();
      const times = isWeekly
        ? this.comegoQuery
            .getAll({
              filterBy: (x) => {
                if (x.type !== 'work') return false;
                const start = new Date(x.start);
                return (
                  x.user.id === user.id,
                  !isSameDay(start, now) &&
                    isWithinInterval(start, {
                      start: startOfWeek(now, { weekStartsOn: 1 }),
                      end: endOfWeek(now, { weekStartsOn: 1 }),
                    })
                );
              },
            })
            .map((x) => {
              return {
                ...x,
                timeDiff: (new Date(x.end).getTime() - new Date(x.start).getTime()) / 1000,
              };
            })
        : [];
      const sched =
        stats &&
        schedule?.enabled &&
        parseScheduleStats(
          schedule as any,
          [
            ...times,
            { start: stats.start.toISOString(), end: stats.start.toISOString(), timeDiff: stats.duration } as any,
          ],
          {
            calculateEveryday: true,
            allowDisabled: true,
            date: stats.start,
          }
        );

      if (sched && !sched.usage[currentDay]) sched.usage[currentDay] = { max: 0, used: 0 };
      else if (sched && !sched.isDayEnabled(currentDay)) sched.usage[currentDay].max = 0;
      const dateOfReference = endOfDay(now.getTime()),
        isPastSchedule = now.getTime() > dateOfReference.getTime(),
        currentDayUsage = sched?.usage[currentDay],
        pastFailure = isPastSchedule && currentDayUsage && currentDayUsage.used < currentDayUsage.max;

      return {
        ...stats,
        schedule: sched && {
          ...sched,
          current: {
            ...currentDayUsage,
            percent: currentDayUsage.used / currentDayUsage.max,
          },
          isPastSchedule,
          pastFailure,
          graphState: pastFailure ? 'warn' : currentDayUsage.used < currentDayUsage.max ? 'accent' : 'success',
          isWeekly: !!isWeekly,
        },
        savedGaps: gaps?.sort(firstBy((x) => x.time, 'desc')),
      };
    })
  );
  toggleSchedGraph() {
    this.scheduleType.update((s) => !s);
  }
  readonly paused$ = this.activeTime$.asObservable().pipe(
    map((x) => {
      if (!x?.type) return null;
      return x.type === 'pause';
    })
  );
  readonly tooltipOptions: NgxTippyProps = {
    onShow({ popper, reference }) {
      const refWidth = reference.getBoundingClientRect().width;
      if (refWidth) popper.style.width = `${refWidth}px`;
    },
    delay: [0, 500],
    offset: [0, -6],
    arrow: false,
    hideOnClick: false,
    interactive: true,
  };
  readonly lastVisit = createRxValue<Date>(null);
  readonly lastVisit$ = combineLatest([this.lastVisit.asObservable(), this.now$]).pipe(
    map(([visit, now]) => {
      if (!visit) return null;
      return {
        visit,
        duration: (now.getTime() - visit.getTime()) / 1000,
        now: now.getTime(),
      };
    })
  );
  ngOnInit(): void {
    const lastVisitData = this.appSettings.getValue()?.config?.last_visit;
    const lastVisit = lastVisitData && new Date(lastVisitData);
    const now = new Date();
    if (!lastVisit || !isValid(lastVisit) || !isSameDay(lastVisit, now))
      this.appSettings.updateByKey(`config.last_visit`, (this.lastVisit.value = new Date()).toISOString());
    else this.lastVisit.value = lastVisit;
  }
  private async handleStart(type?: ComegoTimeType) {
    const cuser = this.userSettingsQuery.getValue();
    const user = cuser.workspace.users.find((x) => x.id === cuser.id);
    const start = roundToNearestMinutes(new Date(), { nearestTo: 1 });
    const checkinData = {
      name: null,
      start,
      end: null,
      type: type ?? 'work',
      user,
    } as any;
    const times = await this.recordService.startCheckin(checkinData).catch(async (err: HttpErrorResponse) => {
      pushError(err);
      return null;
    });
    log.debug(times);
    return times?.[0];
  }
  private async handleUpdate(state: 'pause' | 'play') {
    const time = this.activeTime$.value;
    if (!time) return;
    const tid = time.id;
    const stateConstraint = {
      pause: 'pause',
      play: 'work',
    }[state as string] as ComegoTimeType;
    const getCurrentEntity = () => this.comegoQuery.getEntity(tid);
    let metaWorkingHoursError = false;
    const prevTime = await this.handleStop().catch(async (err: HttpErrorResponse) => {
      if (isWorkingHoursError(err.error) && stateConstraint === 'pause') {
        if (isBreakRuleError(err.error)) metaWorkingHoursError = true;
        return await asyncWrapTimeout(
          () =>
            toPromise(this.comegoQuery.selectAll({ filterBy: (x) => x.id === tid, limitTo: 1 }).pipe(map((x) => x[0]))),
          1000
        ).catch(() => null);
      }
      return null;
    });
    if (!prevTime && !metaWorkingHoursError) return;
    await this.handleStart(stateConstraint);
  }
  private async handleStop() {
    const time = this.activeTime$.value;
    if (!time) return null;
    let end = roundToNearestMinutes(new Date(), { nearestTo: 1 });
    if (isBefore(end, new Date(time.start))) end = new Date(time.start);
    const updatedTime: ComegoTime[] = await this.comego
      .update(
        produce(time, (draft) => {
          draft.end = end < new Date(draft.start) ? new Date(draft.start).toISOString() : end.toISOString();
        })
      )
      .then(this.recordService.handleWorkingHourSuccess.bind(this.recordService))
      .catch(async (err: HttpErrorResponse) => {
        if (err.error?.message === 'errors.times.comego.overlap') {
          const prevTime =
            err.error?.refComegoId &&
            (await this.comego.adapter
              .get(environment.serverUrl + `/get/comego?$filter=id eq '${err.error.refComegoId}'`)
              .toPromise()
              .then((x) => x?.[0]));
          const dialogRef = this.dialog.open(ComeAndGoUpdateDialogComponent, {
            data: {
              entity: prevTime ?? time,
              isPastTime: !!prevTime,
            } as ComeGoUpdateDialogData,
            disableClose: true,
          });
          await dialogRef.afterClosed().toPromise();
        }
        pushError(err);
        return await Promise.reject(err);
      });
    return updatedTime?.[0];
  }
  private async handleManual() {
    this.loading = true;
    await toPromise(this.dialog.open(ComeAndGoCreateDialogComponent).afterClosed()).finally(() => {
      this.loading = false;
    });
  }

  async handle(type: 'start', payload?: any) {
    log.debug('handle', type, payload);
    this.loading = true;
    await Promise.resolve(this[`handle${capitalize(type)}`]?.(payload)).catch((err) => log.error(err));
    this.loading = false;
  }
}
