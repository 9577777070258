import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TeamActivityPageGuard } from '@app/pages/team-activity-page/team-activity-page.guard';
import { Shell } from '@app/shell/shell.service';
import { environment } from '@env/environment';
import { AppSubscriptionGuard } from './app-subscription.guard';
import { BetaGuard } from './shared/beta.guard';

const routes: Routes = [
  {
    path: 'not-found',
    loadChildren: () =>
      import('app/pages/not-found-page/not-found-page.module').then((module) => module.NotFoundPageModule),
    data: { state: 'not-found' },
  },
  {
    path: 'invalid-workspace',
    loadChildren: () =>
      import('app/pages/not-found-workspace-page/not-found-workspace-page.module').then(
        (module) => module.NotFoundWorkspacePageModule
      ),
    data: { state: 'invalid-workspace' },
  },
  ...((!environment.production && [
    {
      path: 'auth/teams',
      loadChildren: () => import('./pages/teams/teams-routing.module').then((x) => x.TeamsRoutingModule),
      data: { state: 'teams.root', title: 'Teams' },
    },
  ]) ||
    []),
  Shell.childRoutes(
    [
      {
        path: 'account/profile',
        loadChildren: () => import('app/account-profile/account-profile.module').then((x) => x.AccountProfileModule),
        data: { state: 'account.profile', title: 'Profile' },
      },
      {
        path: 'dashboard',
        loadChildren: () => import('app/pages/reports-page/reports.module').then((x) => x.ReportsModule),
        data: { state: 'dashboard', title: 'Dashboard' },
        canActivate: [AppSubscriptionGuard],
      },
      {
        path: 'reports',
        redirectTo: 'dashboard',
      },
      {
        path: 'team-activity',
        loadChildren: () =>
          import('app/pages/team-activity-page/team-activity-page.module').then((x) => x.TeamActivityPageModule),
        data: { state: 'team-activity', title: 'team-activity.name' },
        canActivate: [AppSubscriptionGuard, TeamActivityPageGuard],
      },
      {
        path: 'supervise',
        loadChildren: () =>
          import('app/pages/supervisor-page/supervisor-page.module').then((x) => x.SupervisorPageModule),
        data: { state: 'supervise' },
        canActivate: [AppSubscriptionGuard, BetaGuard],
      },
      {
        path: 'profitability',
        loadChildren: () => import('app/pages/insights-page/insights-page.module').then((x) => x.InsightsPageModule),
        data: { state: 'insights', title: 'insights.profitability' },
        canActivate: [AppSubscriptionGuard],
      },
      {
        path: 'automation',
        loadChildren: () => import('app/pages/automation-page/automation.module').then((x) => x.AutomationModule),
        data: { state: 'automation', title: 'automation.name' },
        canActivate: [AppSubscriptionGuard],
      },
      {
        path: 'settings/projects',
        loadChildren: () =>
          import('app/pages/settings/projects/projects.module').then((module) => module.ProjectsModule),
        data: { state: 'projects', title: 'Projects' },
        canActivate: [AppSubscriptionGuard],
      },
      {
        path: 'settings/workspace',
        loadChildren: () =>
          import('app/pages/settings/workspaces/workspaces.module').then((module) => module.WorkspacesModule),
        data: { state: 'workspace', title: 'Workspace' },
      },
      {
        path: 'settings/clients',
        loadChildren: () => import('app/pages/settings/clients/clients.module').then((module) => module.ClientsModule),
        data: { state: 'clients', title: 'Clients' },
        canActivate: [AppSubscriptionGuard],
      },
      {
        path: 'settings/tags',
        loadChildren: () => import('app/pages/settings/tags/tags.module').then((module) => module.TagsModule),
        data: { state: 'tags', title: 'Tags' },
        canActivate: [AppSubscriptionGuard],
      },
      {
        path: 'feed',
        loadChildren: () => import('app/pages/feed-page/feed.module').then(({ FeedModule }) => FeedModule),
        data: { state: 'calendar', title: 'Feed' },
        canActivate: [AppSubscriptionGuard],
      },
      ...(!environment.production
        ? [
            {
              path: 'testing',
              loadChildren: () =>
                import('app/pages/testing-page/testing-page.module').then(({ TestingPageModule }) => TestingPageModule),
              data: { title: 'Dev Tools' },
            },
          ]
        : [{}]),
      {
        path: 'calendar',
        redirectTo: 'feed',
      },
    ],
    {
      canActivate: [],
    }
  ),
  {
    path: 'settings/workspaces/**',
    redirectTo: '/settings/workspace',
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
      preloadingStrategy: (environment.production && PreloadAllModules) || NoPreloading,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
