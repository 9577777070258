import { registerLocaleData } from '@angular/common';
import { HttpBackend, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localeDE_Extra from '@angular/common/locales/extra/de';
import localeEN_Extra from '@angular/common/locales/extra/en';
import localeES_Extra from '@angular/common/locales/extra/es';
import localeFR_Extra from '@angular/common/locales/extra/fr';
import localePTBR_Extra from '@angular/common/locales/extra/pt-PT';
import localeFR from '@angular/common/locales/fr';
import localePTBR from '@angular/common/locales/pt-PT';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EntityRefPipesModule } from '@app/_pipes/entity-ref-pipes/entity-ref-pipes.module';
import { KeyValStoreService } from '@app/_services/key-val-store.service';
import { AngularExpansionModule } from '@app/angular-expansion/angular-expansion.module';
import { NotifierAnimations } from '@app/animations/notifier';
import { initialize } from '@app/app-load-handler';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AppService } from '@app/app.service';
import { OfflineDialogModule } from '@app/components/offline-dialog/offline-dialog.module';
import { SubscriptionStatusRowModule } from '@app/components/subscription-status-row/subscription-status-row.module';
import { CoreModule, I18nService } from '@app/core';
import { MaterialModule } from '@app/material.module';
import { FabricFileIconModule } from '@app/modules/fabric/fabric-file-icon/fabric-file-icon.module';
import { FeedModule } from '@app/pages/feed-page/feed.module';
import { HomeModule } from '@app/pages/home-page/home.module';
import { ProjectPageService } from '@app/pages/settings/projects/project-page/project-page.service';
import { CurrencyConfig } from '@app/services/ngx-currency/currency-config';
import { SharedModule } from '@app/shared';
import { LoaderModule } from '@app/shared/loader/loader.module';
import { MaterialSvgRegistryModule } from '@app/shared/material-svg-registry/material-svg-registry.module';
import { RecordToolbarModule } from '@app/shared/record-toolbar/record-toolbar.module';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { ShellModule } from '@app/shell/shell.module';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PaddleModule } from '@saschwarz/ngx-paddle-wrapper';
import * as Sentry from '@sentry/angular-ivy';
import { NotifierModule } from 'angular-notifier';
import { AngularSvgIconModule, SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { enUS } from 'date-fns/locale';
import { NGX_CURRENCY_CONFIG as CURRENCY_MASK_CONFIG } from 'ngx-currency';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { NgxMaskDirective, NgxMaskService } from 'ngx-mask';
import { NgxSonnerToaster } from 'ngx-sonner';
import { Observable } from 'rxjs';
import {
  ApplicationSettingsQuery,
  ApplicationSettingsStore,
  BaseService,
  ChargebeeService,
  ComegoQuery,
  ComegoService,
  Config,
  FreshdeskService,
  MyTimesQuery,
  MyTimesService,
  ProjectsQuery,
  ProjectsService,
  TimeghostApiModule,
  UserService,
  UserSettingsQuery,
  WorkspacesService,
} from 'timeghost-api';
import { AlertWorkspaceChangeDialogModule } from './components/alert-workspace-change-dialog/alert-workspace-change-dialog.module';
import { FrillButtonModule } from './components/frill-button/frill-button.module';
import { GenericDialogsModule } from './components/generic-dialogs/generic-dialogs.module';
import { NGX_SONNER_CONFIG, SONNER_DEFAULT_CONFIG } from './config/sonner';
import { FeedPageService } from './pages/feed-page/feed.service';
import { TeamsService } from './services/teams.service';
import { MatTooltipTippyModule } from './shared/mat-tooltip-tippy/mat-tooltip-tippy.module';
import { UserAvatarService } from './shared/user-avatar/user-avatar.service';

registerLocaleData(localeDE, 'de', localeDE_Extra);
registerLocaleData(localeEN, 'en', localeEN_Extra);
registerLocaleData(localeES, 'es', localeES_Extra);
registerLocaleData(localeFR, 'fr', localeFR_Extra);
registerLocaleData(localePTBR, 'pt', localePTBR_Extra);
const dateFNSDefaultConfig = new DateFnsConfigurationService();
dateFNSDefaultConfig.setLocale(enUS);
const config: Config = {
  serverUrl: environment.serverUrl,
  clientId: environment.adalConfig.clientId,
  production: environment.production,
  apiAccessUrl: environment.adalConfig.apiAccessUrl,
  services: environment.services,
};
export class SvgInternalLoader implements SvgLoader {
  constructor(private http: HttpClient, private handler: HttpBackend) {}
  getSvg(url: string): Observable<string> {
    return new SvgHttpLoader(new HttpClient(this.handler)).getSvg(url?.replace(/^\//g, location.origin + '/'));
  }
}
export function svgLoaderFactory(http: HttpClient, handler: HttpBackend) {
  return new SvgInternalLoader(http, handler);
}

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super();
  }

  toString() {
    return this.service.currentLang || this.service.getDefaultLang();
  }
}
export function createTranslateLoader(http: HttpClient, handler: HttpBackend) {
  const ver = encodeURIComponent(
    String(environment.releaseName?.match(/(\w+)$/gi)?.[0] ?? environment.hash ?? Date.now())
  );
  return new TranslateHttpLoader(new HttpClient(handler), '/assets/locales/', '.json' + '?v=' + ver);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: svgLoaderFactory,
        deps: [HttpClient, HttpBackend],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, HttpBackend],
      },
    }),
    MatTooltipTippyModule.forRoot(),
    MaterialModule,
    CoreModule,
    SharedModule,
    ShellModule,
    NotifierModule.withConfig({
      position: {
        vertical: {
          position: 'top',
          distance: ((x) => (isTeamsWindow() ? x - 64 : x))(136),
        },
        horizontal: {
          position: 'right',
        },
      },
      theme: 'material',
      animations: NotifierAnimations,
    }),
    HomeModule.forRoot(),
    HttpClientModule,
    TimeghostApiModule.forRoot(config),
    LoaderModule,
    NgxMaskDirective,
    AngularExpansionModule,
    UserAvatarModule.forRoot(),
    FeedModule.forRoot(),
    RecordToolbarModule.forRoot(),
    FabricFileIconModule.forRoot(),
    OfflineDialogModule,
    GenericDialogsModule,
    DateFnsModule.forRoot(),
    MaterialSvgRegistryModule,
    PaddleModule,
    EntityRefPipesModule,
    FrillButtonModule.forRoot(),
    SubscriptionStatusRowModule,
    AlertWorkspaceChangeDialogModule,
    NgxSonnerToaster,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  providers: [
    ...(environment.production
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
              logErrors: true,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
        ]
      : []),
    KeyValStoreService,
    I18nService,
    TeamsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [
        BaseService,
        I18nService,
        AppService,
        TeamsService,
        UserSettingsQuery,
        UserService,
        WorkspacesService,
        ProjectsService,
        MyTimesService,
        MyTimesQuery,
        ComegoService,
        ComegoQuery,
        ApplicationSettingsStore,
        ApplicationSettingsQuery,
        ProjectsQuery,
        RecordToolbarService,
        Router,
        MsalService,
        UserAvatarService,
        TranslateService,
        HttpClient,
      ],
      multi: true,
      useExisting: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: environment.production ? [Sentry.TraceService] : [],
      multi: true,
    },
    ChargebeeService,
    FreshdeskService,
    AppService,
    NgxMaskService,
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService],
    },
    { provide: DateFnsConfigurationService, useValue: dateFNSDefaultConfig },
    {
      provide: CURRENCY_MASK_CONFIG,
      useClass: CurrencyConfig,
    },
    {
      provide: NGX_SONNER_CONFIG,
      useValue: SONNER_DEFAULT_CONFIG,
    },
    ProjectPageService,
  ],
  declarations: [AppComponent],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private feedPageService: FeedPageService) {
    this.feedPageService.initialize();
  }
}
