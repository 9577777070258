<div class="container-fluid pr-4">
  <div class="flex flex-row items-center justify-start gap-2">
    <div class="flex flex-auto flex-row items-center justify-start gap-2">
      <button
        mat-icon-button
        (click)="zoomOut()"
        [disabled]="this.selectedSegment <= 2"
        [matTooltip]="'timer.calendar.zoomOut' | translate"
      >
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="zoomIn()"
        [disabled]="this.selectedSegment >= 12"
        [matTooltip]="'timer.calendar.zoomIn' | translate"
      >
        <mat-icon>zoom_in</mat-icon>
      </button>
      <mat-divider vertical></mat-divider>
      <button mat-button (click)="todayView()" [disabled]="isLoading">
        <mat-icon>today</mat-icon>
        <span class="ml-2">{{ 'timer.calendar.today' | translate }}</span>
      </button>
      <button mat-icon-button (click)="prevView()" [disabled]="isLoading">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-icon-button (click)="rangePicker.toggle()" [satPopoverAnchor]="rangePicker" [disabled]="isLoading">
        <mat-icon>calendar_today</mat-icon>
      </button>
      <button mat-icon-button (click)="nextView()" [disabled]="isLoading">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <div class="flex flex-row items-center justify-start gap-1.5">
      <h3>
        {{ viewDate | dfnsStartOfISOWeek | dfnsFormat: 'MMM dd':{ weekStartsOn: weekStartsOn } }}
        -
        {{ viewDate | dfnsEndOfISOWeek | dfnsFormat: 'MMM dd':{ weekStartsOn: weekStartsOn } }}
        ({{ 'timer.calendar.kw' | translate: { value: (viewDate | dfnsGetISOWeek) } }})
      </h3>
      <button
        mat-icon-button
        tabindex="-1"
        [matTooltip]="'timer.calendar.settings' | translate"
        [matMenuTriggerFor]="calendarOptions"
      >
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </div>
</div>
<ng-template
  #weekViewHourSegmentTemplate
  let-segment="segment"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel"
>
  <div
    #segmentElement
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass"
    (mousedown)="startDragToCreate(segment, $event, segmentElement)"
  >
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.date | formatDate: 'time' }}
    </div>
  </div>
</ng-template>
<ng-template
  #tgEvent
  let-weekEvent="weekEvent"
  let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked"
  let-tooltipTemplate="tooltipTemplate"
  let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDisabled="tooltipDisabled"
  let-tooltipDelay="tooltipDelay"
  let-column="column"
  let-daysInWeek="daysInWeek"
>
  <mat-card
    class="tg-event-item pos-relative select-none"
    (contextmenu)="
      weekEvent.event.meta?.loading === true || !!weekEvent.event.meta?.feed || onContextMenu($event, weekEvent)
    "
    matTooltip
    [matTooltipTemplate]="eventViewTooltip"
    [matTooltipDisabled]="weekEvent.event.meta?.tmpEvent"
    [matTooltipTemplateContext]="{
      $implicit: weekEvent,
      daysInWeek: daysInWeek
    }"
    (mwlClick)="
      weekEvent.event.meta?.loading === true ||
        weekEvent.event.meta?.time?.project?.completed ||
        eventClicked.emit({ sourceEvent: $event })
    "
    (mwlKeydownEnter)="
      weekEvent.event.meta?.loading === true ||
        weekEvent.event.meta?.time?.project?.completed ||
        eventClicked.emit({ sourceEvent: $event })
    "
    tabindex="0"
    role="button"
    [ngClass]="{
      'cursor-default! opacity-60': weekEvent.event.meta?.time?.project?.completed,
      'tg-event-item-billable': weekEvent.event?.meta?.time?.billable === true,
      'tg-event-item-outlook': weekEvent.event?.meta?.type === 'calendar'
    }"
  >
    <app-loader [isActive]="weekEvent.event.meta?.loading === true" [enableCircle]="true" [width]="24"></app-loader>
    <div *ngIf="weekEvent.event.meta.tmpEvent; else timeView" class="truncate">
      {{ weekEvent.event?.title || '' }}
    </div>
    <ng-template #timeView>
      <ng-container *ngIf="weekEvent.event.meta.time as time">
        <div
          *ngIf="this.getProjectColor(time.project.id) as color"
          class="project-card-color"
          [style.backgroundColor]="color"
        ></div>
        <div class="truncate" *ngIf="time.name">{{ time.name }}</div>
        <div class="flex flex-row items-baseline justify-start gap-1.5">
          <div
            *ngIf="this.getProjectColor(time.project.id) as color"
            class="project-color-dot project-color-dot-calendar"
            [style.backgroundColor]="color"
          ></div>
          <div>
            {{ time.project | defaultEntityTranslate: 'project':'project.none' }}
          </div>
        </div>
        <div class="flex flex-col">
          <small *ngIf="time.inputMode !== 'duration'">
            {{ weekEvent.event.start | formatDate: 'time' }} -
            {{ weekEvent.event.end | formatDate: 'time' }}
          </small>
          <small *ngIf="weekEvent.event.meta.outlook">{{ 'timer.calendar.created-from' | translate }} Outlook</small>
        </div>
      </ng-container>
      <ng-container *ngIf="weekEvent.event.meta.feed as time">
        <div class="flex flex-row items-start justify-start gap-1">
          <div *ngIf="weekEvent.event.meta.type === 'calendar'" class="project-feed-icon mt-1 flex-shrink-0">
            <img src="/assets/svg/icons/outlook.svg" alt="outlook logo" />
          </div>
          <div>{{ time.name }}</div>
        </div>
        <small>
          {{ weekEvent.event.start | formatDate: 'time' }} -
          {{ weekEvent.event.end | formatDate: 'time' }}
        </small>
      </ng-container>
    </ng-template>
  </mat-card>
</ng-template>
<ng-template
  #tgHourTemplate
  let-segment="segment"
  let-locale="locale"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel"
  let-daysInWeek="daysInWeek"
>
  <div
    [attr.aria-hidden]="{} | calendarA11y: (daysInWeek === 1 ? 'hideDayHourSegment' : 'hideWeekHourSegment')"
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass"
  >
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.displayDate | formatDate: 'time' }}
    </div>
  </div>
</ng-template>
<div class="tgc-wrapper">
  <app-loader [isActive]="this.isLoading$ | async" [enableCircle]="true" [width]="24"></app-loader>
  <mwl-calendar-week-view
    class="cal-week-view-host"
    #mwlCal
    [viewDate]="viewDate"
    [events]="events$ | async"
    (eventTimesChanged)="this.eventTimesChanged($event)"
    (eventClicked)="this.onEventClicked($event)"
    [hourSegmentTemplate]="weekViewHourSegmentTemplate"
    [headerTemplate]="eventWeekHeader"
    [refresh]="this.viewRefresh"
    [eventTemplate]="tgEvent"
    [hourSegments]="this.selectedSegment"
    [eventSnapSize]="this.selectedSegmentSnapSize"
    [weekStartsOn]="1"
    [dayStartHour]="startFromWorkDay ? 6 : 0"
    [dayEndHour]="23"
  >
  </mwl-calendar-week-view>
</div>
<ng-template
  #eventWeekHeader
  let-days="days"
  let-locale="locale"
  let-dayHeaderClicked="dayHeaderClicked"
  let-eventDropped="eventDropped"
  let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
  let-dragEnter="dragEnter"
>
  <div class="cal-day-headers" role="row">
    <div
      class="cal-header flex select-none flex-col"
      *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
      [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday"
      [class.cal-future]="day.isFuture"
      [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass"
      tabindex="0"
      role="columnheader"
    >
      <div class="flex flex-row items-center justify-start">
        <div class="flex flex-auto flex-col text-left">
          <b>{{ day.date | dfnsFormat: 'EEEE' }}</b>
          <span>{{ day.date | dfnsFormat: 'MMM dd' }}</span>
        </div>
        <ng-container *ngIf="getStatsFromDate(day) as sched">
          <ng-container *ngTemplateOutlet="scheduleView; context: { $implicit: sched }"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #eventViewTooltip let-daysInWeek="daysInWeek" let-weekEvent>
  <div class="px-1 py-1.5">
    <ng-container *ngIf="weekEvent.event?.meta?.time?.id && weekEvent.event as ev">
      <div class="flex flex-col">
        <div>
          {{
            ev.title
              | calendarEventTitle
                : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
                : weekEvent.tempEvent || weekEvent.event
          }}
        </div>
        <div class="flex flex-row items-center justify-start gap-1">
          <span>{{ ev.meta.time.project | defaultEntityTranslate: 'project':'project.none' }}</span>
          <ng-container *ngIf="ev.meta.time.task as task">
            <div class="mat-bg-30 h-1 w-1 rounded-full"></div>
            <span>{{ task.name | truncate: 25 }}</span>
          </ng-container>
        </div>
        <div class="flex items-center space-x-0.5">
          <span class="mat-hint italic">{{ ev.meta.time.timeDiff | parseMsAsDuration }}</span>
          <ng-container *ngIf="ev.meta.time?.inputMode !== 'duration'">
            <span>-</span><span>({{ ev.start | formatDate: 'time' }} - {{ ev.end | formatDate: 'time' }})</span>
          </ng-container>
        </div>
        <div *ngIf="ev.meta.time.billable">
          {{ 'Billable_Alt' | translate }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="weekEvent.event?.meta?.feed && weekEvent.event as ev">
      <div class="flex flex-row items-center justify-start gap-1">
        <div *ngIf="weekEvent.event.meta.type === 'calendar'" class="project-feed-icon">
          <img src="/assets/svg/icons/outlook.svg" alt="outlook logo" />
        </div>
        <div class="flex flex-col space-y-0.5">
          <div>
            {{
              ev.title
                | calendarEventTitle
                  : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
                  : weekEvent.tempEvent || weekEvent.event
            }}
          </div>
          <div class="flex items-center space-x-0.5">
            <span class="mat-hint italic">{{ ev.start | dfnsDifferenceInSeconds: ev.end | parseMsAsDuration }}</span>
            <ng-container *ngIf="ev.meta.time?.inputMode !== 'duration'">
              <span>-</span><span>({{ ev.start | formatDate: 'time' }} - {{ ev.end | formatDate: 'time' }})</span>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<mat-menu #eventContextMenu="matMenu">
  <ng-template matMenuContent let-event="event">
    <button
      mat-menu-item
      (click)="this.onEventClicked({ event: event })"
      [disabled]="event.meta.time?.project?.completed"
    >
      {{ 'timer.calendar.eventContext.edit' | translate }}
    </button>
    <button mat-menu-item (click)="this.duplicateEvent(event)" [disabled]="event.meta.time?.project?.completed">
      {{ 'timer.calendar.eventContext.duplicate' | translate }}
    </button>
    <button
      mat-menu-item
      (click)="this.removeEvent(event)"
      class="text-warn"
      [disabled]="event.meta.time?.project?.completed"
    >
      {{ 'timer.calendar.eventContext.remove' | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #calendarOptions="matMenu">
  <ng-template matMenuContent>
    <mat-card class="mat-elevation-z0 flex flex-col gap-1" (click)="$event.stopPropagation()">
      <mat-checkbox
        [checked]="this.startFromWorkDay"
        (change)="this.startFromWorkDay = $event.checked"
        color="primary"
        >{{ 'timer.calendar.showWorkHours' | translate }}</mat-checkbox
      >
    </mat-card>
  </ng-template>
</mat-menu>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  #eventContextTrigger="matMenuTrigger"
  [matMenuTriggerFor]="eventContextMenu"
></div>
<tg-time-tracker-calendar-week-picker
  #rangePicker
  [viewDate]="viewDate"
  (rangeChange)="selectView($event)"
></tg-time-tracker-calendar-week-picker>
<ng-template #scheduleView let-sched>
  <div
    class="mat-typography mat-bg-5-hover grid h-12 grid-cols-[1fr_28px] items-center gap-x-3 rounded-lg px-4"
    matTooltip
    [matTooltipTemplate]="scheduleTooltip"
    [matTooltipTemplateContext]="{ $implicit: sched }"
  >
    <div class="flex flex-col text-right text-sm">
      <span>{{ sched.current.used | parseMsAsDuration }}</span>
      <span>{{ sched.current.max | parseMsAsDuration }}</span>
    </div>
    <div class="mat-circle-card">
      <mat-progress-spinner
        [value]="sched.current.percent * 100 | clamp: 0:100"
        strokeWidth="3"
        [diameter]="20"
        [color]="sched.graphState"
        class="with-back-spinner"
      ></mat-progress-spinner>
    </div>
  </div>
</ng-template>
<ng-template #scheduleTooltip let-sched>
  <div class="mat-typography flex flex-col px-3 py-2.5">
    <div class="flex items-center space-x-2">
      <span>{{ sched.name || ('schedules.name' | translate) }}</span>
      <ng-container *ngIf="sched.current.percent >= 0">
        <span class="dot size-1.5"></span>
        <span>{{ sched.current.percent | percent }}</span>
      </ng-container>
    </div>
  </div>
</ng-template>
