import { Component } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FadeInOut, SlideUpDown, SlideUpDownBump } from '@app/animations/fade';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MediaObserver } from 'ngx-flexible-layout';
import { map } from 'rxjs/operators';
import { Logger, Time } from 'timeghost-api';

const log = new Logger('home');

export const TIMEFORMAT = {
  parse: {
    dateInput: 'H:mm:ss',
  },
  display: {
    dateInput: 'H:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [FadeInOut, SlideUpDown, SlideUpDownBump],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: TIMEFORMAT }],
})
export class HomeComponent {
  readonly isMobile$ = this.media
    .asObservable()
    .pipe(map((x) => x.findIndex((y) => ['xs', 'sm'].includes(y.mqAlias)) !== -1));
  readonly isMobileCalendar$ = this.media
    .asObservable()
    .pipe(map((x) => x.findIndex((y) => ['xs', 'sm'].includes(y.mqAlias)) !== -1));
  constructor(private recordService: RecordToolbarService, private media: MediaObserver) {}
  openCreateDialog() {
    return this.recordService.openCreateDialog();
  }
  openUpdateDialog(time: Time, forceUpdate: boolean = false) {
    return this.recordService.openUpdateDialog(time, forceUpdate);
  }
  startRecord() {
    return this.recordService.startRecord();
  }
  stopRecord() {
    return this.recordService.stopRecord();
  }
}
