import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from '@env/environment';
import { IsBetaWindow } from '@env/version';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BetaGuard {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !environment.production || IsBetaWindow();
  }
}
