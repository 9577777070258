<ng-container *rxIf="times$; let times; else: emptyTemplate">
  <ng-container
    *rxFor="
      let group of times;
      let groupIndex = index;
      let last = last;
      let first = first;
      patchZone: false;
      trackBy: 'id'
    "
  >
    <ng-container *ngIf="group.state.showMonthDivider">
      <div class="divider my-2">
        <div class="divider-text flex flex-col items-center justify-center" [style.width.px]="220">
          <span class="mat-hint text-sm">{{ group.date | dfnsFormat: 'MMMM' }}</span>
        </div>
      </div>
    </ng-container>
    <app-time-tracker-table-card
      [data]="group"
      [class.mat-border-b]="!last"
      [class.rounded-bottom]="last"
      [class.rounded-top]="first"
    >
      <ng-template timeCardContent>
        <ng-container *ngIf="this.selectedDisplayMode$ | push as mode">
          <ng-container
            *ngTemplateOutlet="
              group.times.length > 0
                ? mode === 'Grouped'
                  ? groupedTable
                  : mode === 'Simple'
                  ? simpleTable
                  : null
                : emptyTemplate;
              context: { $implicit: group }
            "
          >
          </ng-container>
        </ng-container>
      </ng-template>
    </app-time-tracker-table-card>
  </ng-container>
</ng-container>
<ng-template #loadingCards>
  <div class="flex flex-col gap-[15px]">
    <mat-card *ngFor="let __d of [0, 0, 0, 0, 0]; let i = index">
      <mat-card-title-group class="group-header flex flex-row items-start justify-start">
        <mat-card-title class="group-date">
          <div style="height: 20px; width: 80px">
            <div class="mat-skeleton"></div>
          </div>
        </mat-card-title>
        <mat-card-subtitle class="group-date-full">
          <div style="height: 20px; width: 60px">
            <div class="mat-skeleton"></div>
          </div>
        </mat-card-subtitle>
        <div class="flex-auto"></div>
        <div class="flex flex-row items-center justify-start gap-3">
          <div class="flex flex-col gap-1">
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
          </div>
        </div>
      </mat-card-title-group>
      <mat-card-content>
        <div style="height: 20px; width: 100%">
          <div class="mat-skeleton"></div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
<ng-template #groupedTable let-group>
  <ng-container
    *rxFor="let item of group.times; patchZone: false; strategy: 'immediate'; let itemindex = index; trackBy: 'id'"
  >
    <ng-container *ngIf="item.times.length > 1 && item.times as dItems; else oneItem">
      <mat-accordion displayMode="flat" [multi]="true" [hideToggle]="false">
        <mat-expansion-panel #panel [hideToggle]="true" class="mat-elevation-z0 mat-expansion-native --time-group mb-0">
          <mat-expansion-panel-header
            class="t-pad px-3"
            expandedHeight="48px"
            [class.--item-entry-wrapper]="panel.expanded"
          >
            <ng-container
              *ngTemplateOutlet="
                (this.isMobile$ | push) ? mobileGroup : normalGroup;
                context: { $implicit: item, dItems: dItems }
              "
            ></ng-container>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="flex flex-col items-stretch justify-start gap-2">
              <div class="--item-entry-container flex flex-col items-stretch justify-start gap-2 px-2 pb-2 pt-0">
                <app-time-tracker-entry
                  *rxFor="let dtime of dItems; patchZone: false; strategy: 'immediate'; trackBy: 'id'"
                  [attr.data-object-id]="dtime.id"
                  [updateOnChange]="false"
                  (Deleted)="this.removeItem($event)"
                  (DateUpdate)="this.entryDateChange.next($event)"
                  [isCompleted]="group.completed"
                  [entry]="dtime"
                >
                </app-time-tracker-entry>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <ng-template #oneItem>
      <app-time-tracker-entry
        *rxFor="let dtime of item.times; patchZone: false; strategy: 'immediate'; trackBy: 'id'"
        [updateOnChange]="false"
        (Deleted)="this.removeItem($event)"
        [isCompleted]="group.completed"
        [entry]="dtime"
        class="--table-time-entry"
      >
      </app-time-tracker-entry>
    </ng-template>
  </ng-container>
</ng-template>
<ng-template #simpleTable let-group>
  <app-time-tracker-entry
    *rxFor="
      let item of group.times | mapProp: 'times' | flat | sort: { start: 'desc', end: 'desc' };
      let itemindex = index;
      patchZone: false;
      trackBy: 'id'
    "
    [updateOnChange]="false"
    (Deleted)="this.removeItem($event)"
    [isCompleted]="group.completed"
    [entry]="item"
    class="--table-time-entry"
  >
  </app-time-tracker-entry>
</ng-template>
<ng-template #mobileGroup let-item let-dItems="dItems">
  <div class="flex w-full flex-row items-center justify-start gap-3 truncate">
    <div class="flex flex-row items-center justify-start">
      <span
        [style.transform]="'scale((panel.expanded ? .85 : 1))'"
        class="badge badge-transparent badge-stroked t-transform"
        >{{ item.times.length }}</span
      >
    </div>
    <div class="group-name flex-auto truncate">
      <span class="truncate text-nowrap">{{ item.min.task?.name ?? ('task.none' | translate) }}</span>
      <div class="group-project-badge flex flex-row items-center justify-start gap-1 text-sm">
        <span
          class="mat-hint group-project-name truncate text-nowrap text-right"
          *ngIf="dItems[0]?.project | defaultEntityTranslate: 'project':'project.none' as projectName"
          >{{ projectName }}</span
        >
        <div class="mat-bg-30 h-1 w-1 flex-shrink-0 rounded-full"></div>
        <span>{{ dItems[0]?.task?.name || ('task.none' | translate) | truncate: 25 }}</span>
      </div>
    </div>
    <div class="flex flex-row items-center justify-start gap-3">
      <div style="width: 32px" fxHide.lt-md></div>
      <mat-icon
        inline
        class="group-header-icons"
        [class]="item.billable ? { 'mat-accent': true } : { 'mat-hint': true }"
        >attach_money</mat-icon
      >
      <div class="ml-0 mr-1" fxHide.lt-md></div>
      <div class="flex flex-shrink-0 flex-col gap-[2px]">
        <small>{{ this.parseExactDuration(item) }}</small>
        <small>
          {{ item.min.start | dfnsParseIso | formatDate: 'time' }} -
          {{ item.max.end | dfnsParseIso | formatDate: 'time':'narrow' }}
        </small>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #normalGroup let-item let-dItems="dItems">
  <div class="flex w-full flex-row items-center justify-start gap-3 text-ellipsis">
    <div class="flex flex-row items-center justify-start">
      <span
        [style.transform]="'scale((panel.expanded ? .85 : 1))'"
        class="badge badge-transparent badge-stroked t-transform"
        >{{ item.times.length }}</span
      >
    </div>
    <div class="group-name text-ellipsis">
      <span class="text-ellipsis text-nowrap">{{ item.task.name ?? ('task.none' | translate) }}</span>
    </div>
    <div class="!ml-auto text-ellipsis">
      <div
        class="group-project-badge mr-3/4-em flex flex-row items-center justify-start gap-1.5"
        *ngIf="dItems[0].project as project"
      >
        <div
          *ngIf="this.getProjectColor(project.id) as color"
          class="project-color-dot"
          [style.backgroundColor]="color"
        ></div>
        <span
          class="mat-hint group-project-name text-ellipsis text-nowrap text-right"
          *ngIf="project | defaultEntityTranslate: 'project':'project.none' as projectName"
          >{{ projectName }}</span
        >
        <mat-icon class="mat-hint" inline> folder </mat-icon>
      </div>
    </div>
    <div class="flex flex-row items-center justify-start gap-[10px]">
      <div fxHide.lt-md class="hidden md:flex md:w-[32px]"></div>
      <mat-icon
        inline
        class="group-header-icons flex w-[32px] flex-shrink-0 flex-col items-center justify-center"
        [class]="item.billable ? { 'mat-accent': true } : { 'mat-hint': true }"
        >attach_money</mat-icon
      >
      <div class="flex flex-row items-center justify-start gap-[10px] text-center">
        <span class="mat-hint w-[86px] flex-shrink-0">
          {{ item.min.start | dfnsParseIso | formatDate: 'time' }}
        </span>
        <mat-icon inline class="mat-hint">arrow_forward</mat-icon>
        <span class="mat-hint w-[86px] flex-shrink-0">
          {{ item.max.end | dfnsParseIso | formatDate: 'time' }}
        </span>
      </div>
    </div>
    <div
      class="badge w-[64px] flex-shrink-0 text-center"
      matTooltip
      [matTooltipTemplate]="durationHoverCard"
      [matTooltipTemplateContext]="{ timeDiff: item.sum }"
    >
      {{ item.sum | parseMsAsDuration: false }}
    </div>
    <div class="hidden flex-shrink-0 md:flex md:w-[40px]"></div>
  </div>
</ng-template>
<ng-template #durationHoverCard let-timeDiff="timeDiff">
  <div class="flex flex-row items-center justify-start gap-1">
    <span>{{ 'timer.time.duration' | translate }}:</span>
    <span>{{ timeDiff | parseMsAsDuration: true }}</span>
  </div>
</ng-template>
