<div
  id="wrapper"
  *ngIf="{
    tab: (this.selectedTab.value$ | async),
    mode: (this.selectedDisplayMode$ | async)
  } as view"
  class="flex flex-col"
>
  <tg-toolbar tbclass="mat-toolbar-tg mat-toolbar-base --timer-rc-header mat-elevation-z0" showFrill></tg-toolbar>
  <div
    class="record-toolbar-card mat-bg-card relative z-10 shadow-lg"
    [ngClass]="{ 'h-16': view.tab === 'work' }"
    *ngIf="this.isMobile$comego | async"
  >
    <ng-container *ngTemplateOutlet="view.tab === 'work' ? comegoToolbar : projectToolbar"></ng-container>
    <ng-template #projectToolbar>
      <div class="container">
        <app-record-toolbar #recordToolbar [isLoading]="this.recordLoading$ | async"> </app-record-toolbar>
      </div>
    </ng-template>
    <!-- not my idea -->
    <ng-template #comegoToolbar>
      <ng-container *ngIf="comegoEnabled$ | async">
        <div class="container mx-auto flex h-16 items-center">
          <tg-come-and-go-box></tg-come-and-go-box>
        </div>
      </ng-container>
    </ng-template>
  </div>
  <ng-container
    *ngTemplateOutlet="view.mode === 'Calendar' ? calendarHost : view.mode === 'ComeGo' ? comegoHost : defaultHost"
  ></ng-container>
  <ng-template #comegoHost>
    <div class="timer-content-wrapper">
      <div class="timer-content">
        <div class="container">
          <div class="time-entries-wrapper mt-1 flex flex-col pb-[256px]">
            <ng-container *ngTemplateOutlet="tableHead"></ng-container>
            <tg-time-comego-view></tg-time-comego-view>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #defaultHost>
    <div class="timer-content-wrapper">
      <div class="timer-content">
        <div class="container">
          <div class="time-entries-wrapper mt-1 flex flex-col pb-[256px]">
            <ng-container *ngTemplateOutlet="tableHead"></ng-container>
            <app-time-tracker-table
              [showCompleted]="this.showCompleted"
              [byDayTreshold]="this.byDayTreshold$ | async"
              [range]="rangeValue$ | async"
              [emptyTemplate]="noTableItems"
            >
            </app-time-tracker-table>
            <div class="my-3" *ngIf="this.isLoading$ | async">
              <ng-container *ngTemplateOutlet="loadingCards"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #calendarHost>
  <div class="container">
    <div class="mt-2 flex flex-row items-center justify-start gap-2 text-left">
      <div class="wflex-[1_1_12px] xl:flex-auto"></div>
      <div class="justify-content-stretch flex flex-shrink-0 space-x-3 xl:justify-start">
        <ng-container>
          <ng-container *rxIf="times$schedule; let sched">
            <ng-container
              *ngTemplateOutlet="
                scheduleWeekView;
                context: {
                  $implicit: sched,
                  isProjectTab: isComegoAvailable$ | async
                }
              "
            ></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="viewToggleRow"></ng-container>
      </div>
    </div>
  </div>
  <app-time-tracker-calendar class="container"></app-time-tracker-calendar>
</ng-template>
<ng-template #noTableItems>
  <div class="flex h-32 items-center justify-center">
    {{ 'utils.noitems-time' | translate }}
  </div>
</ng-template>
<ng-template #tableHead>
  <div class="mb-3 mt-2 flex items-center text-left" ngClass.lt-xl="justify-center">
    <shell-menu-toggle [teamsOnly]="true"></shell-menu-toggle>
    <div class="flex flex-col xl:flex-auto xl:flex-row">
      <div class="flex space-x-1" *ngIf="viewDate$status | async as vd">
        <button
          mat-button
          class="mat-button-square !rounded-lg"
          (click)="prev()"
          [disabled]="(this.isLoading$ | async) || (!vd.isFuture && !vd.isValidRange)"
          [matTooltip]="'utils.older' | translate"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          mat-button
          class="mt-1 h-10 space-x-1"
          [satPopoverAnchor]="dayPickerPopover"
          (click)="dayPickerPopover.toggle()"
          [disabled]="this.isLoading$ | async"
          [matTooltip]="'utils.pickTimeRange' | translate"
        >
          <ng-container *ngIf="vd.sameDay; else vdRange">
            <span>{{ vd.start | formatDate: 'iiii' }}</span>
            <span>{{ vd.start | formatDate: 'PP' }}</span>
            <span>({{ 'timer.calendar.kw' | translate: { value: vd.start | formatDate: 'I' } }})</span>
          </ng-container>
          <ng-template #vdRange>
            <span>{{ vd.start | formatDate: 'PP' }} - {{ vd.end | formatDate: 'PP' }}</span>
            <span>({{ 'timer.calendar.kw' | translate: { value: vd.start | formatDate: 'I' } }})</span>
          </ng-template>
        </button>
        <button
          mat-button
          class="mat-button-square !rounded-lg"
          (click)="next()"
          [disabled]="(this.isLoading$ | async) || (vd.isFuture && !vd.isValidRange)"
          [matTooltip]="'utils.newer' | translate"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button
          mat-icon-button
          [matTooltip]="'timer.calendar.today' | translate"
          (click)="today()"
          [disabled]="(this.isLoading$ | async) || vd.sameWeek"
        >
          <mat-icon>today</mat-icon>
        </button>
      </div>
      <div class="wflex-[1_1_12px] xl:flex-auto"></div>
      <div class="justify-content-stretch flex flex-shrink-0 space-x-3 xl:justify-start">
        <ng-container>
          <ng-container *rxIf="times$schedule; let sched">
            <ng-container
              *ngTemplateOutlet="
                scheduleWeekView;
                context: {
                  $implicit: sched,
                  isProjectTab: isComegoAvailable$ | async
                }
              "
            ></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="viewToggleRow"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loadingCards>
  <div class="flex flex-col gap-[15px]">
    <mat-card *ngFor="let __d of [0, 0, 0, 0, 0]; let i = index">
      <mat-card-title-group class="group-header flex flex-row items-start justify-start">
        <mat-card-title class="group-date">
          <div class="flex flex-col gap-1">
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
            <div style="height: 20px; width: 60px">
              <div class="mat-skeleton"></div>
            </div>
          </div>
        </mat-card-title>
        <div class="flex-auto"></div>
        <div class="flex flex-row items-center justify-start gap-3">
          <div class="flex flex-col gap-1">
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
            <div style="height: 20px; width: 80px">
              <div class="mat-skeleton"></div>
            </div>
          </div>
        </div>
      </mat-card-title-group>
      <mat-card-content>
        <div class="mat-skeleton" style="height: 20px"></div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
<ng-template #viewToggleRow>
  <ng-template #viewToggleContent>
    <ng-container *ngIf="selectedDisplayMode$ | async as mode">
      <div class="flex justify-end space-x-1" *ngIf="selectedTab.value$ | async as cat">
        <ng-container *ngIf="cat === 'project'">
          <button
            [class.bg-primary-2]="!displayModeLoading && mode === 'Simple'"
            mat-icon-button
            color="primary"
            [send-tagmanager_event]="{
              category: 'feature',
              action: 'button tableDisplayMode tableDisplayMode_Simple'
            }"
            (click)="this.selectTableDisplayMode(this.tableDisplayTypes.Simple)"
            [matTooltip]="'utils.tableDisplayMode.simple' | translate"
            [disabled]="displayModeLoading"
            class="flex flex-col items-center justify-center"
          >
            <ng-container *ngTemplateOutlet="displayModeLoading === 'Simple' ? btnLoading : simpleIcon"></ng-container>
          </button>
          <button
            [class.bg-primary-2]="!displayModeLoading && mode === 'Grouped'"
            mat-icon-button
            color="primary"
            [send-tagmanager_event]="{
              category: 'feature',
              action: 'button tableDisplayMode tableDisplayMode_Grouped'
            }"
            (click)="this.selectTableDisplayMode(this.tableDisplayTypes.Grouped)"
            [matTooltip]="'utils.tableDisplayMode.grouped' | translate"
            [disabled]="displayModeLoading"
            class="flex flex-col items-center justify-center"
          >
            <ng-container
              *ngTemplateOutlet="displayModeLoading === 'Grouped' ? btnLoading : groupedIcon"
            ></ng-container>
          </button>
          <button
            [class.bg-primary-2]="!displayModeLoading && mode === 'Calendar'"
            mat-icon-button
            color="primary"
            [send-tagmanager_event]="{
              category: 'feature',
              action: 'button tableDisplayMode tableDisplayMode_Calendar'
            }"
            *ngIf="!(this.isMobileCalendar$ | async)"
            (click)="this.selectTableDisplayMode(this.tableDisplayTypes.Calendar)"
            [matTooltip]="'utils.tableDisplayMode.calendar' | translate"
            [disabled]="displayModeLoading"
            class="flex flex-col items-center justify-center"
          >
            <ng-container
              *ngTemplateOutlet="displayModeLoading === 'Calendar' ? btnLoading : calendarIcon"
            ></ng-container>
          </button>
        </ng-container>
        <ng-container *ngIf="cat === 'work'">
          <button
            [class.bg-primary-2]="!displayModeLoading && mode === 'ComeGo'"
            mat-icon-button
            color="primary"
            [send-tagmanager_event]="{
              category: 'feature',
              action: 'button tableDisplayMode tableDisplayMode_Comego'
            }"
            (click)="this.selectTableDisplayMode(this.tableDisplayTypes.ComeGo)"
            class="flex flex-col items-center justify-center !rounded-full"
            [matTooltip]="'utils.tableDisplayMode.comego' | translate"
            [disabled]="displayModeLoading"
          >
            <ng-container *ngTemplateOutlet="displayModeLoading === 'ComeGo' ? btnLoading : comegoIcon"></ng-container>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
  <div class="flex flex-col space-y-2">
    <ng-container *ngIf="!(comegoOnly$ | async)">
      <div
        class="flex h-12 items-center justify-end space-x-2 rounded-lg border border-gray-200/80 bg-white px-2 shadow shadow-gray-700/10 dark:border-neutral-800 dark:bg-transparent dark:shadow-none"
        *ngIf="selectedTab.value$ | async as cat"
      >
        <ng-container *ngIf="comegoEnabled$ | async">
          <div class="relative" *ngIf="{ comegoTimesAdded: comegoTimesAdded$ | async } as actions">
            <button
              [class.bg-primary-2]="!displayModeLoading && cat === 'work'"
              mat-button
              color="primary"
              [send-tagmanager_event]="{
                category: 'feature',
                action: 'button tableDisplayMode tableDisplayMode_Simple'
              }"
              (click)="tabChange('work')"
              [disabled]="displayModeLoading"
              class="flex flex-col items-center justify-center !rounded-lg"
            >
              {{ 'times.workTab' | translate }}
            </button>
            <div
              class="bg-warn absolute bottom-full right-2 -mb-1 rounded-full px-1.5 text-xs font-bold text-white"
              *ngIf="actions.comegoTimesAdded"
            >
              NEW
            </div>
          </div>
        </ng-container>

        <div class="relative" *ngIf="{ projectTimesAdded: projectTimesAdded$ | async } as actions">
          <button
            [class.bg-primary-2]="!displayModeLoading && cat === 'project'"
            mat-button
            color="primary"
            [send-tagmanager_event]="{
              category: 'feature',
              action: 'button tableDisplayMode tableDisplayMode_Simple'
            }"
            (click)="tabChange('project')"
            [disabled]="displayModeLoading"
            class="flex flex-col items-center justify-center !rounded-lg"
          >
            {{ 'times.projectTab' | translate }}
          </button>
          <div
            class="bg-warn absolute bottom-full right-2 -mb-1 rounded-full px-1.5 text-xs font-bold text-white"
            *ngIf="actions.projectTimesAdded"
          >
            NEW
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="viewToggleContent"> </ng-container
    ></ng-container>
  </div>
  <ng-template #simpleIcon>
    <mat-icon>list</mat-icon>
  </ng-template>
  <ng-template #groupedIcon>
    <mat-icon>view_stream</mat-icon>
  </ng-template>
  <ng-template #calendarIcon>
    <mat-icon>event</mat-icon>
  </ng-template>
  <ng-template #comegoIcon>
    <mat-icon>view_timeline</mat-icon>
  </ng-template>
  <ng-template #btnLoading>
    <mat-spinner mode="indeterminate" color="primary" [diameter]="18"></mat-spinner>
  </ng-template>
</ng-template>
<ng-template #autoModeGhosts>
  <div class="time-entries-wrapper flex flex-col">
    <div class="d-none flex flex-row items-center">
      <div class="justfiy-items-center align-items-start flex flex-grow flex-col">
        <div class="help-container cursor-pointer" (click)="this.focusElementById('record_toolbar_name')">
          <mat-icon inline svgIcon="record_help_1" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-timer-1' | translate }}</span>
        </div>
      </div>
      <div class="justfiy-items-center align-items-start flex flex-col" style="width: 240px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_project')">
          <mat-icon inline svgIcon="record_help_2" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-timer-2' | translate }}</span>
        </div>
      </div>
      <div class="justfiy-items-center align-items-start flex flex-col" style="width: 160px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_timer_submit')">
          <mat-icon inline svgIcon="record_help_timer_3" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-timer-3' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #manualModeGhost>
  <div class="time-entries-wrapper flex flex-col">
    <div class="d-none flex flex-row items-center">
      <div class="justfiy-items-center align-items-start flex flex-grow flex-col">
        <div class="help-container cursor-pointer" (click)="this.focusElementById('record_toolbar_name')">
          <mat-icon inline svgIcon="record_help_1" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-1' | translate }}</span>
        </div>
      </div>
      <div class="justfiy-items-center align-items-start flex flex-col" style="width: 290px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_project')">
          <mat-icon inline svgIcon="record_help_2" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-2' | translate }}</span>
        </div>
      </div>
      <div class="justfiy-items-center align-items-start flex flex-col" style="width: 288px">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_start')">
          <mat-icon inline svgIcon="record_help_3" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-3' | translate }}</span>
        </div>
      </div>
      <div class="justfiy-items-center align-items-start flex flex-col" style="width: 60">
        <div class="help-container cursor-pointer" (click)="this.clickElementById('record_toolbar_submit')">
          <mat-icon inline svgIcon="record_help_4" class="help-icon"></mat-icon>
          <span>{{ 'record-toolbar.help.step-4' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #widgetLoadingRef>
  <mat-progress-spinner [strokeWidth]="2" [diameter]="18" class="mat-white" mode="indeterminate">
  </mat-progress-spinner>
</ng-template>
<tg-day-picker-popover
  #dayPickerPopover
  [min]="minRangeStart"
  [max]="maxRangeStart"
  [viewDate]="range.value$ | async"
  (viewDateChange)="range.update($event)"
  rangeType="week"
></tg-day-picker-popover>
<ng-template #appRow let-label="label" let-content="content">
  <div
    class="flex h-12 items-center space-x-4 rounded-lg border border-gray-200/80 bg-white px-4 shadow shadow-gray-700/10 dark:border-neutral-800 dark:bg-transparent dark:shadow-none"
  >
    <h1 class="text-muted text-sm">{{ label }}</h1>
    <div class="flex items-center space-x-2">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #appListContent>
  <div class="-mr-3 flex space-x-1.5">
    <button
      mat-button
      [matTooltip]="'feed.name' | translate"
      [routerLink]="'/feed'"
      class="mat-button-square !rounded-lg"
      [send-tagmanager_event]="{
        category: 'feature',
        action: 'button timerShortcut timerShortcut_feed'
      }"
    >
      <mat-icon svgIcon="fcalendar" class="route-icon-calendar"></mat-icon>
    </button>
    <button
      mat-button
      *ngIf="viewDate$ | async as vd"
      [matTooltip]="'reports.detailed' | translate"
      [routerLink]="'/dashboard/detailed'"
      [queryParams]="{
        start: vd.start.toISOString(),
        end: vd.end.toISOString()
      }"
      class="mat-button-square !rounded-lg"
      [send-tagmanager_event]="{
        category: 'feature',
        action: 'button timerShortcut timerShortcut_dashboard'
      }"
    >
      <mat-icon svgIcon="fdashboard" class="route-icon-dashboard"></mat-icon>
    </button>
  </div>
</ng-template>
<ng-template #scheduleWeekView let-sched let-isProjectTab="isProjectTab">
  <div
    class="mat-bd-5 mat-typography grid h-12 items-center gap-x-6 rounded-lg px-4"
    [ngClass]="{ 'grid-cols-[1fr_28px]': isProjectTab }"
    matTooltip
    [matTooltipTemplate]="scheduleTooltip"
    [matTooltipTemplateContext]="{ $implicit: sched }"
    [matTooltipDisabled]="!isProjectTab"
  >
    <div class="flex flex-col text-right" [ngClass]="{ 'text-sm': isProjectTab }">
      <span>{{ sched.used | parseMsAsDuration }}</span>
      <span *ngIf="isProjectTab">{{ sched.max | parseMsAsDuration }}</span>
    </div>
    <div class="mat-circle-card" *ngIf="isProjectTab">
      <mat-progress-spinner
        [value]="sched.percent * 100 | clamp: 0:100"
        strokeWidth="3"
        [diameter]="20"
        [color]="sched.graphState"
        class="with-back-spinner"
      ></mat-progress-spinner>
    </div>
  </div>
</ng-template>
<ng-template #scheduleTooltip let-sched>
  <div class="mat-typography flex flex-col px-3 py-2.5">
    <div class="flex items-center space-x-2">
      <span>{{ sched.name || ('schedules.name' | translate) }}</span>
      <ng-container *ngIf="sched.percent >= 0">
        <span class="dot size-1.5"></span>
        <span>{{ sched.percent | percent }}</span>
      </ng-container>
    </div>
  </div>
</ng-template>
