import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatSort } from '@angular/material/sort';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { toPromise } from '@app/_helpers/promise';
import {
  createDateRangeArray,
  createScheduleDayParserFromUser,
  distinctUntilChangedJson,
  fromRxValue,
} from '@app/_helpers/utils';
import { AppService } from '@app/app.service';
import { GradientColor } from '@app/classes/gradient';
import { I18nService } from '@app/core';
import { RecordToolbarComponent } from '@app/shared/record-toolbar/record-toolbar.component';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import {
  TableDisplayType,
  TimeTrackerTableComponent,
} from '@app/shared/time-tracker-table/time-tracker-table.component';
import { ShellComponent } from '@app/shell/shell.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { toDate } from 'date-fns-tz/esm';
import { addMinutes, endOfMinute, isValid, isWithinInterval, startOfMinute, sub } from 'date-fns/esm';
import { format, setMilliseconds, startOfDay } from 'date-fns/esm/fp';
import { flow } from 'lodash-es';
import { MediaObserver } from 'ngx-flexible-layout';
import { BehaviorSubject, Subject, combineLatest, timer } from 'rxjs';
import {
  auditTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { ComegoQuery, Logger, MyTimesQuery, UserSchedule, UserService, UserSettingsQuery } from 'timeghost-api';

import { disabledFlags } from '@app/shared/time-comego-view/time-comego-view.component';
import { EntityActions } from '@datorama/akita';
import { HomeComponent } from '../home.component';
import { HomeService } from '../home.service';

const log = new Logger('home');

export const TIMEFORMAT = {
  parse: {
    dateInput: 'H:mm:ss',
  },
  display: {
    dateInput: 'H:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const tabelDisplayMap = {
  [TableDisplayType.Calendar]: 'Calendar',
  [TableDisplayType.Grouped]: 'Grouped',
  [TableDisplayType.Simple]: 'Simple',
  [TableDisplayType.ComeGo]: 'ComeGo',
};
@UntilDestroy()
@Component({
  selector: 'app-home-timer',
  templateUrl: './home-timer.component.html',
  styleUrls: ['./home-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeTimerComponent implements OnInit, AfterViewInit, OnDestroy {
  _onDestroy: Subject<void> = new Subject<void>();
  @ViewChild('recordToolbar', { static: false })
  recordToolbar: RecordToolbarComponent;
  @ViewChild(TimeTrackerTableComponent, { static: true })
  table: TimeTrackerTableComponent;
  @ViewChild('timer', { static: true })
  timer: ElementRef;
  @ViewChild(MatSort, { static: true })
  recordSortTable: MatSort;
  counter: number;
  running = false;
  timerIntervalRef: any;

  headerColor: GradientColor;
  quote: string;
  @ViewChild('projectSelector', { static: true })
  _user: any;
  private _showCompleted: BehaviorSubject<boolean> = new BehaviorSubject(false);

  showCompleted = this._showCompleted.asObservable();

  setShowCompleted = (ev: MatCheckboxChange) => this._showCompleted.next(ev.checked);
  readonly isLoading = fromRxValue(
    combineLatest([this.myTimeQuery.selectLoading().pipe(startWith(false))]).pipe(
      auditTime(100),
      map(([timesLoading]) => timesLoading),
      distinctUntilChanged()
    )
  );
  readonly isLoading$ = this.isLoading.asObservable();

  readonly timeRunning$ = this.myTimeQuery.selectAll({ filterBy: (x) => !x.end, limitTo: 1 }).pipe(
    map((times) => times[0]),
    finalize(() => {
      this.cdr.detectChanges();
    })
  );
  constructor(
    private language: I18nService,
    private media: MediaObserver,
    public shell: ShellComponent,
    private appService: AppService,
    private i18nService: TranslateService,
    private userService: UserService,
    private myTimeQuery: MyTimesQuery,
    private comegoQuery: ComegoQuery,
    private userSettingsQuery: UserSettingsQuery,
    private parent: HomeComponent,
    private recordService: RecordToolbarService,
    private cdr: ChangeDetectorRef,
    private home: HomeService
  ) {
    log.debug('home construct');
    // todo unbedingt ändern, nicht gut gelößt
  }
  readonly isMobile$ = this.parent.isMobile$;
  readonly isMobileCalendar$ = this.parent.isMobileCalendar$;
  navToggle() {
    this.shell.sidenav.toggle();
  }
  get isManualMode() {
    return !!this.userSettingsQuery.getValue()?.settings.captureManualMode;
  }
  tableDisplayTypes: typeof TableDisplayType = TableDisplayType;
  readonly selectedDisplayMode$ = this.userSettingsQuery.select((x) => x.settings.tableDisplay);
  readonly comegoOnly$ = this.appService.comegoOnly$;
  displayModeLoading: string = null;
  async selectTableDisplayMode(mode: TableDisplayType) {
    const tableDisplay = tabelDisplayMap[mode] || ('Simple' as any);
    if (mode !== TableDisplayType.ComeGo) localStorage?.setItem?.('lastHomeTab', mode);
    if (tableDisplay === this.userSettingsQuery.getValue().settings.tableDisplay) {
      return;
    }
    window.dataLayer.push({
      Timer_View: mode,
    });
    this.displayModeLoading = tableDisplay;
    await this.userService
      .changeSettings({
        tableDisplay,
      })
      .toPromise()
      .finally(() => {
        this.displayModeLoading = null;
      });
  }
  get isTeams() {
    return this.appService.isTeams();
  }
  readonly hasEntries$ = combineLatest([this.myTimeQuery.selectAll(), this.myTimeQuery.selectLoading()]).pipe(
    map(([times]) => times),
    takeUntil(this._onDestroy),
    map((x) => x?.length > 0),
    distinctUntilChanged()
  );
  get isnavOpen() {
    return this.shell.sidenav.opened;
  }
  get hasLoadMore() {
    return this.myTimeQuery.getHasMore();
  }
  focusElementById(expr: string) {
    document.getElementById(expr)?.focus();
  }
  clickElementById(expr: string) {
    document.getElementById(expr)?.click();
  }
  checkContentScroll(el: HTMLDivElement) {
    return el && el.scrollTop && el.scrollTop > 64;
  }
  get minRangeStart() {
    return this.home.minRangeStart;
  }
  get maxRangeStart() {
    return this.home.maxRangeStart;
  }
  get range() {
    return this.home.range;
  }
  readonly rangeValue$ = this.home.range.asObservable();
  readonly viewDate$ = this.home.viewDate$;
  readonly viewDate$status = this.home.viewDate$status;
  prev() {
    return this.home.prev();
  }
  today() {
    return this.home.today();
  }
  next() {
    return this.home.next();
  }
  private _byDayTreshold = new BehaviorSubject<number>(7);
  readonly byDayTreshold$ = this._byDayTreshold.asObservable().pipe(distinctUntilChanged());
  readonly byDayTresholdRange = this.byDayTreshold$.pipe(
    map((x) => {
      let from = sub(new Date(), { days: x });
      let to = new Date();
      return { from, to };
    })
  );

  get byDayTreshold() {
    return this._byDayTreshold.getValue();
  }
  set byDayTreshold(val: number) {
    this._byDayTreshold.next(val);
  }
  readonly selectedTab = fromRxValue(
    this.selectedDisplayMode$.pipe(map((x) => (['Simple', 'Grouped', 'Calendar'].includes(x) ? 'project' : 'work')))
  );
  readonly isMobile$comego = combineLatest([this.selectedTab.asObservable(), this.parent.isMobile$]).pipe(
    map(([tab, isMobile]) => tab === 'work' || !isMobile)
  );

  readonly projectTimesEntityActions = fromRxValue(this.myTimeQuery.selectEntityAction(EntityActions.Add));
  readonly projectTimesAdded = fromRxValue(
    combineLatest([
      this.selectedTab.asObservable(),
      this.projectTimesEntityActions.asObservable(),
      this.rangeValue$,
    ]).pipe(
      map(([tab, ids, range]) => {
        if (tab !== 'work') return false;
        if (!ids) return false;
        return !!ids.find((id) => {
          const entity = this.myTimeQuery.getEntity(id);
          if (!entity || !entity.end) return false;
          const startDate = addMinutes(startOfMinute(new Date()), -5);
          const endDate = endOfMinute(new Date());
          const timeCreatedAt = new Date(entity['created']);
          return isWithinInterval(timeCreatedAt, {
            start: startDate,
            end: endDate,
          });
        });
      })
    )
  );

  readonly projectTimesAdded$ = this.projectTimesAdded.asObservable();
  comegoTimesEntityActions = fromRxValue(this.comegoQuery.selectEntityAction(EntityActions.Add));
  readonly comegoTimesAdded = fromRxValue(
    combineLatest([
      this.selectedTab.asObservable(),
      this.comegoTimesEntityActions.asObservable(),
      this.rangeValue$,
      this.userSettingsQuery.select(),
    ]).pipe(
      map(([tab, ids, range, user]) => {
        if (tab === 'work') return false;
        if (!ids) return false;
        return !!ids.find((id) => {
          const entity = this.comegoQuery.getEntity(id);
          if (!entity || !entity.end || user.id !== entity.user?.id || user.defaultWorkspace !== entity.workspace?.id)
            return false;
          const startDate = addMinutes(startOfMinute(new Date()), -5);
          const endDate = endOfMinute(new Date());
          const timeCreatedAt = new Date(entity['created']);
          return isWithinInterval(timeCreatedAt, {
            start: startDate,
            end: endDate,
          });
        });
      })
    )
  );
  readonly comegoTimesAdded$ = this.comegoTimesAdded.asObservable();
  async tabChange(tabName: 'project' | 'work') {
    const prevTab = this.selectedTab.value;
    this.selectedTab.next(tabName);
    if (prevTab !== tabName) {
      const tableDisplay =
        tabName === 'work'
          ? TableDisplayType.ComeGo
          : (localStorage.lastHomeTab && Object.values(TableDisplayType).find((x) => x === localStorage.lastHomeTab)
              ? (localStorage.lastHomeTab as TableDisplayType)
              : TableDisplayType.Simple) ?? TableDisplayType.Simple;
      this.projectTimesEntityActions.update(null);
      this.comegoTimesEntityActions.update(null);
      await this.selectTableDisplayMode(tableDisplay);
      await this.home.fetchBetween(this.range.value.start, this.range.value.end);
    }
  }
  ngOnInit() {
    if (this.appService.comegoOnly.value && this.selectedTab.value !== 'work') {
      this.tabChange('work');
    }
    if (this.media.isActive(['xs', 'sm']) && this.userSettingsQuery.getValue().settings.tableDisplay === 'Calendar') {
      this.selectTableDisplayMode(TableDisplayType.Simple);
      this.cdr.markForCheck();
    }
    combineLatest([this.isMobileCalendar$, this.userSettingsQuery.select((x) => x.settings.tableDisplay)])
      .pipe(
        filter(([x, displayMode]) => !!x && displayMode === 'Calendar'),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.selectTableDisplayMode(TableDisplayType.Simple);
        this.cdr.markForCheck();
      });

    const user = this.userSettingsQuery.getValue();
    if (!user.workspace?.settings?.comego && (user.settings.tableDisplay as any) === 'ComeGo') {
      this.isLoading.next(true);
      toPromise(
        this.userService.changeSettings({
          tableDisplay: 'Simple',
        })
      ).finally(() => {
        this.isLoading.next(false);
      });
    }

    this.rangeValue$
      .pipe(
        untilDestroyed(this),
        filter((d) => !!d),
        distinctUntilChangedJson(({ start, end }) => ({
          start: start?.toISOString(),
          end: end?.toISOString(),
        })),
        debounceTimeAfterFirst(80),
        switchMap(async (range) => {
          return await this.home.fetchBetween(range.start, range.end);
        })
      )
      .subscribe();
  }
  ngAfterViewInit(): void {}
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  get counterDate() {
    return flow(startOfDay, setMilliseconds(this.counter), format('H:mm:ss'))(new Date());
  }
  recordLoading$ = this.recordService.saveLoading$;
  onScrolledBottom = new Subject<void>();
  onBottomThreshold(el: ElementRef<HTMLDivElement>, force?: boolean) {
    log.debug('bottom hit');
    if (force === true || !el || el.nativeElement.scrollHeight - el.nativeElement.scrollTop > 200)
      this.onScrolledBottom.next();
  }
  now() {
    return new Date();
  }
  get currentDate(): Date {
    return toDate(new Date(), { locale: { code: this.lang } });
  }
  get lang(): string {
    return this.language.language;
  }
  clearTimer() {
    log.debug(this.counter);
    this.counter = undefined;
    localStorage.removeItem('pTimerStart');
    clearInterval(this.timerIntervalRef);
    this.cdr.markForCheck();
  }
  openCreateDialog() {
    return this.parent.openCreateDialog();
  }
  readonly comegoEnabled$ = this.appService.comegoEnabled$;
  readonly isComegoAvailable$ = combineLatest([this.selectedTab.asObservable(), this.appService.comegoEnabled$]).pipe(
    map(([tab, comegoEnabled]) => {
      if (comegoEnabled && tab === 'work') return true;
      return false;
    })
  );
  readonly times$schedule = combineLatest([
    this.home.range.asObservable(),
    this.userSettingsQuery.select(),
    this.selectedTab.asObservable(),
    combineLatest([this.myTimeQuery.selectAll(), this.home.range.asObservable()]).pipe(
      map(([times, range]) => times.filter((t) => isWithinInterval(new Date(t.start), range)))
    ),
    combineLatest([
      this.comegoQuery.selectAll(),
      this.home.range.asObservable(),
      this.userSettingsQuery.select(),
      timer(0, 5000).pipe(
        withLatestFrom(this.comegoQuery.selectAll({ filterBy: (x) => !x.end, limitTo: 1 })),
        filter(([, x]) => !!x.find((d) => !d.end)),
        startWith(0)
      ), // update combine if a recording is running
    ]).pipe(
      untilDestroyed(this),
      map(([times, range, user]) =>
        times
          .filter((t) => user.id === t.user.id && isWithinInterval(new Date(t.start), range))
          .map((x) => {
            const end = new Date(x.end || Date.now());
            return {
              ...x,
              timeDiff: (end.getTime() - new Date(x.start).getTime()) / 1000 || 0,
            };
          })
      )
    ),
  ]).pipe(
    map(([range, user, tab, times, comegoTimes]) => {
      const now = Date.now(),
        isPastSchedule = now > range.end.getTime();
      const isWork = tab === 'work';

      const sched = createDateRangeArray(range.start, range.end, false)[0].reduce((acc, [d]) => {
        const data = createScheduleDayParserFromUser(user, d)(
          ((isWork ? comegoTimes : times) as any[]).filter((x) => {
            const dt = new Date(x.start);
            if (!isValid(dt)) return false;
            if (isWork && disabledFlags.includes(x.type)) return false;
            return isWithinInterval(dt, range);
          }),
          d
        );
        if (!data?.enabled) return acc;
        if (!acc) {
          acc = { used: data.usage, max: data.cap, schedule: data.schedule };
        } else {
          acc.used += data.usage;
          acc.max += data.cap;
        }
        return acc;
      }, null as { used: number; max: number; schedule: UserSchedule });
      if (!sched) return null;
      const pastFailure = isPastSchedule && sched.used < sched.max,
        percent = sched.used / sched.max;
      return {
        ...sched,
        isPastSchedule,
        pastFailure,
        percent,
        graphState: pastFailure ? 'warn' : sched.used < sched.max ? 'accent' : 'success',
      };
    })
  );
}
