import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, EventType as RouterEventType, Router } from '@angular/router';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { differenceInSeconds } from 'date-fns/esm';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import {
  ComegoQuery,
  ComegoService,
  ComegoStore,
  ComegoTime,
  Logger,
  MyTimesQuery,
  MyTimesService,
  NotifyService,
  Time,
  UserService,
  UserSettingsQuery,
} from 'timeghost-api';

import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';
import { ObjectScale, ObjectSlideAnimation } from './animations/fade';
import { AppService } from './app.service';
import {
  AlertWorkspaceChangeDialogComponent,
  createWorkspaceChangeObserver,
} from './components/alert-workspace-change-dialog/alert-workspace-change-dialog.component';
import { FrillButtonService } from './components/frill-button/frill-button.service';
import { OfflineDialogComponent } from './components/offline-dialog/offline-dialog.component';
import { MaterialSvgRegistryService } from './shared/material-svg-registry/material-svg-registry.service';
import parseSubscriptionAsStatus from './_helpers/parseSubscriptionAsStatus';
import { toPromise } from './_helpers/promise';
import { KeyValStoreService } from './_services/key-val-store.service';

const log = new Logger('App');
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ObjectScale, ObjectSlideAnimation],
})
export class AppComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  readonly workspace$state = this.userSettingsQuery
    .select()
    .pipe(map((x) => parseSubscriptionAsStatus(x.workspace, x)));
  get loadingActive() {
    return this.keyvalStore.isLoadingActive;
  }
  private getIE() {
    let myNav = navigator.userAgent.toLowerCase();
    return myNav.indexOf('msie') !== -1 ? parseInt(myNav.split('msie')[1], 0) : false;
  }
  get isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
  get isIE() {
    let ie = this.getIE();
    return ie !== false && ie <= 10;
  }
  errClosed: boolean = false;
  closeErr() {
    this.errClosed = true;
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private keyvalStore: KeyValStoreService,
    private translateService: TranslateService,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private appService: AppService,
    private comegoService: ComegoService,
    private comegoQuery: ComegoQuery,
    private meta: Meta,
    private matSvgRegister: MaterialSvgRegistryService,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    private frillService: FrillButtonService,
    private myTimesService: MyTimesService,
    private myTimesQuery: MyTimesQuery
  ) {
    this.meta.updateTag(
      {
        content: 'width=device-width, initial-scale=1.0, minimum-scale=0.75, maximum-scale= 1.25',
      },
      'name="viewport"'
    );
    this.meta.addTag({
      name: 'app-version',
      content: environment.version,
    });
  }
  get fullAppLoading() {
    return this.appService.checkLoading('fullAppLoading');
  }
  get isDarkMode() {
    return this.appService.selectedTheme === 'dark';
  }
  ngOnInit() {
    this.matSvgRegister.initialize();
    log.debug('init');
    const userSettings = this.userSettingsQuery.getValue();
    const prefLang = userSettings.settings.languageSetting || 'en-US';
    window.document.querySelector('html').setAttribute('lang', prefLang.split('-')[0]);
    // Change page title on navigation or language change, based on route data
    combineLatest([
      this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
      this.router.events.pipe(
        filter((ev: any) => {
          return ev?.type === RouterEventType.NavigationEnd;
        })
      ),
    ])
      .pipe(
        map(([, ev]: [any, ActivationEnd]) => {
          log.debug('router', ev);
          let route = this.activatedRoute.snapshot;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        map((route) => route.data as any)
      )
      .subscribe((data) => {
        const title = data?.['title'];
        const defaultTitle = 'timeghost';
        if (title) {
          this.titleService.setTitle(`${this.translateService.instant(title)} - timeghost`);
        } else if (this.titleService.getTitle() !== defaultTitle) {
          this.titleService.setTitle(defaultTitle);
        }
      });
    this.fillAkitaStoresAsync();
    if (!environment.production)
      this.myTimesService.getLatestRecordings(null, 'comego').then((x) => log.debug('latest comego recordings', x));
    if (!environment.production)
      this.userSettingsQuery.select().subscribe((x) =>
        log.debug({
          user: x,
          status: parseSubscriptionAsStatus(x.workspace, x),
        })
      );

    this.loadRunningRecordings();
  }
  private async loadRunningRecordings() {
    const user = this.userSettingsQuery.getValue();
    await Promise.all([
      toPromise(
        this.comegoService.adapter.get<ComegoTime[]>(
          environment.serverUrl +
            `/get/comego?$filter=workspace__id eq '${user.workspace.id}' and user__id eq '${user.id}' and end eq null`
        )
      ).then((times) => {
        if (times?.length) (this.comegoQuery.__store__ as ComegoStore).upsertMany(times);
      }),
      toPromise(
        this.myTimesService.adapter.get<Time[]>(
          environment.serverUrl +
            `/get/times?$filter=workspace__id eq '${user.workspace.id}' and user__id eq '${user.id}' and end eq null`
        )
      ).then((times) => {
        if (times?.length) (this.myTimesQuery.__store__ as MyTimesStore).upsertMany(times);
      }),
    ]);
  }
  private _offlineDialogId: string;
  private openOfflineDialog() {
    if (!this._offlineDialogId || !this.dialog.getDialogById(this._offlineDialogId)) {
      const dialog = this.dialog.open(OfflineDialogComponent, {
        closeOnNavigation: false,
        disableClose: true,
      });
      this._offlineDialogId = dialog.id;
      dialog.afterClosed().subscribe(() => (this._offlineDialogId = null));
      return dialog;
    }
  }
  async ngAfterViewInit() {
    this.appService.isOnline$
      .pipe(
        filter((x) => !x),
        debounceTime(500)
      )
      .subscribe(() => {
        this.openOfflineDialog();
      });
    this.appService.browserWoken.asObservable().subscribe(() => (this.openOfflineDialog(), log.debug('browserWoken')));
    this.appService.onResume
      .asObservable()
      .pipe(
        filter(() => !this.appService.isSignalInstanceConnected),
        debounceTime(1000)
      )
      .subscribe(() => this.appService.initSignalReconnectOnFailed());
    this.appService.initialize();
  }
  private fillAkitaStoresAsync() {
    return this.appService.reinitializeStores(['workspaces', 'projects']);
  }
  private _timeBlur: Date;
  private _timeBlurLoading: boolean = false;
  private checkSyncConnectivity(type: 'focus' | 'blur', ...args: any[]) {
    if (this._timeBlurLoading) return;
    const now = new Date();
    const focusTime = this._timeBlur;
    log.debug(type, focusTime ? differenceInSeconds(now, focusTime) : null, ...args);
    if (type === 'focus') {
      this._timeBlur = null;
      if (focusTime && differenceInSeconds(now, focusTime) > 300) {
        // re fetch stores
        this._timeBlurLoading = true;
        this.appService
          .reinitializeStores([], { disableRemove: true })
          .finally(() => {
            this._timeBlurLoading = false;
          })
          .catch((err) => {
            log.debug('timeblur err>', err);
          });
      } else if (!this.appService.isSignalInstanceConnected) this.appService.connectSignal(true);
    } else if (type === 'blur') {
      this._timeBlur = new Date();
    }
  }
  syncConnectivityEvent(...args: any[]) {
    return this.checkSyncConnectivity('focus', ...args);
  }
  syncConnectivityBlurEvent(...args: any[]) {
    return this.checkSyncConnectivity('blur', ...args);
  }
  ngAfterContentInit() {
    this.initialLoadingEnd();
    window.fcWidget?.hide();
    this.appService.initSignalReconnectOnFailed();
    this.frillService.getFrillWidget();
    if (!environment.production)
      this.notifyService.onMessage.pipe(untilDestroyed(this)).subscribe((x) => log.debug('[notify] :: debug>', x));

    window.addEventListener('focus', this.syncConnectivityEvent.bind(this));
    window.addEventListener('blur', this.syncConnectivityBlurEvent.bind(this));
  }
  ngOnDestroy(): void {
    window.removeEventListener('focus', this.syncConnectivityEvent.bind(this));
    window.removeEventListener('blur', this.syncConnectivityBlurEvent.bind(this));
  }
  readonly rtcActive$ = this.appService.signalActive.asObservable();
  initialLoadingEnd() {
    let appLoad: HTMLDivElement;
    if (!(appLoad = document.querySelector('#appLoad'))) {
      return;
    }

    appLoad.remove();
    if (navigator && navigator.serviceWorker)
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    (function () {
      var ug = navigator.userAgent.toLowerCase();
      if (
        ug.indexOf('msie') !== -1 ||
        ug.indexOf('trident') !== -1 ||
        (ug.indexOf('safari') !== -1 && ug.indexOf('chrome') === -1 && ug.indexOf('mozilla') === -1)
      ) {
        document.querySelector('#outdated-browser-err').removeAttribute('style');
      }
    })();

    (() => {
      let dialogRef: MatDialogRef<any>;
      createWorkspaceChangeObserver()
        .pipe(untilDestroyed(this))
        .subscribe((x) => {
          const user = this.userSettingsQuery.getValue();
          if (!dialogRef && x && user && x.workspaceId !== user.workspace?.id) {
            dialogRef = this.dialog.open(AlertWorkspaceChangeDialogComponent, {
              disableClose: true,
            });
            dialogRef.afterClosed().subscribe(() => {
              dialogRef = null;
            });
          }
        });
    })();
  }
}
